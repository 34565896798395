import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const CategoryItems = ({ categoryName, sortAtoZ }) => {
  const data = useStaticQuery(graphql`
    query getCategoriesData {
      allMdx(sort: { fields: frontmatter___date, order: DESC }) {
        nodes {
          frontmatter {
            category
            slug
            title
            image {
              childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG)
              }
            }
          }
        }
      }
    }
  `)

  const {
    allMdx: { nodes },
  } = data

  let posts = nodes

  // if sortAtoZ is true, posts are sorted by alphabetically by title
  if (sortAtoZ === true) {
    posts = nodes.sort(function (a, b) {
      return a.frontmatter.title.localeCompare(b.frontmatter.title)
    })
  }

  const filteredFrontMatterByCategoryName = posts.filter(
    post => post.frontmatter.category === categoryName
  )
  console.log(filteredFrontMatterByCategoryName)
  return (
    <div className="category-card">
      <h3>{categoryName}</h3>
      {/* ここから記事リンク */}
      {filteredFrontMatterByCategoryName.map((post, index) => {
        return (
          <Link key={index} to={`/posts/${post.frontmatter.slug}`}>
            <div className="post-card">
              <GatsbyImage
                image={getImage(post.frontmatter.image)}
                alt={post.frontmatter.title}
                className="post-img"
              />
              <div>
                <p>{post.frontmatter.title}</p>
              </div>
            </div>
          </Link>
        )
      })}
    </div>
  )
}

export default CategoryItems
