import React from "react"
import CategoryItems from "./category-items"

const SelectedCategory = () => {
  return (
    <>
      <div className="sidebar">
        <CategoryItems categoryName="Shopify" />
        <CategoryItems categoryName="Web Development" />
        <CategoryItems categoryName="MBA経営戦略論" sortAtoZ={true} />
        <CategoryItems categoryName="統計学" />
      </div>
    </>
  )
}

export default SelectedCategory
