import React from "react"
import SelectedCategory from "../components/categories/selected-categories"

const Blog = () => {
  return (
    <section className="blog-posts">
      <section className="blog-categories">
        <h1>blog</h1>
        <div className="blog-category">
          <h2>Shopipy</h2>
          <p>Shopifyについてのおぼえがき</p>
        </div>
        <div className="blog-category">
          <h2>ウェブ制作</h2>
          <p>ウェブ制作についての覚え書き</p>
        </div>
        <div className="blog-category">
          <h2>MBA経営戦略論</h2>
          <p>米国MBAで教えられる経営戦略論『Strategic Management』についての</p>
        </div>
        <div className="blog-category">
          <h2>Shopify</h2>
          <p>Shopifyについてのおぼえがき</p>
        </div>
      </section>
      <section>
        <SelectedCategory />
      </section>
    </section>
  )
}

export default Blog
